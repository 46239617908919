h1, h2, h3, h4, h5, h6, p, li, ul, a, span, strong, button, button.ui.button, div {
    font-family: Europa;
}

.App {
    font-family: Europa;
    .content-container {
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .main-login-form {
        padding-top: 20vh;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
    }

    .page-container {
        padding: 10px;
    }

    .ui.input.changed {
        input {
            font-style: italic;
        }
    }
}