.collection-editor {
    .collection-items {
        h3 {
            font-size: 1em;
            padding: 0px;
            margin: 0px;
        }
        p {
            font-size: 1em;
            color: grey;
            padding: 0px;
            margin: 0px;
        }
        .droppable-div {
            &::-webkit-scrollbar {
                width: 2px;
            }
        }
    }
    .controls-row {
        .ui.input {
            padding-left: 20px;
            margin-top: -3px;
            margin-right: 30px;
        }
    }
    .add-table-media-container {
        position: relative;
        overflow: visible;

        .ui.label {
            position: absolute;
            top: -5px;
            right: 5px;
            z-index: 1;
            opacity: 0.8;
        }
        img {
            width: 100%  !important;
            height: 100% !important;
            object-fit: contain !important;
        }
        video {
            width: 100% !important;
            height: 100% !important;
            object-fit: contain !important;
        }
    }
}