.main-menu {
    &.ui.segment {
        border-radius: 0px;
        margin: 0px !important;
        padding: 0px !important;
        button.ui.button {
            border: 0px solid transparent !important;
            padding: 6px;
            padding-left: 12px;
            padding-right: 12px;
            box-shadow: none !important;
        }
    }
    
}